import React from "react";
import Spacing from "../../Spacing";
import { Link } from "react-router-dom";

export default function AboutSection({
  imgUrl,
  spiningImgUrl,
  title,
  subTitle,
  featureList,
}) {
  return (
    <section className="cs_about cs_style_1">
      <div className="container">
        <div className="row align-items-center flex-lg-row flex-column-reverse cs_gap_y_40">
          <div className="col-lg-7">
            <Spacing md="55" />
            <div className="cs_about_img">
              <img src={imgUrl} alt="About" />
              <div className="cs_about_mini_img">
                <img
                  src={spiningImgUrl}
                  alt="Mini"
                  className="cs_rotate_animation"
                />
                <img
                  src="/images/icons/tick.svg"
                  alt="Right"
                  className="cs_right_icon"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="cs_about_content">
              <div className="cs_section_heading cs_style_1">
                <h2 className="cs_section_title cs_fs_72 m-0">{title}</h2>
                <div className="cs_height_5" />
                <h3 className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold m-0 cs_accent_color cs_fs_32">
                  {subTitle}
                </h3>
              </div>
              {/* <Spacing md="72" lg="72" /> */}
              <ul className="cs_about_text_list">
                {featureList.map((item, index) => (
                <Link to="/about">  <p  key={index}>
                    <h3 className="cs_normal cs_fs_32 cs_body_color">
                      {item.featureListTitle}
                    </h3>
                    <p className="m-0">{item.featureListSubTitle}</p>
                  </p>
                  </Link>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
