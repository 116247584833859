import React from "react";
import { Helmet } from 'react-helmet';
import BannerSectionStyle3 from "../Section/BannerSection/BannerSectionStyle3";
import Section from "../Section";
import DepartmentSectionStyle2 from "../Section/DepartmentSection/DepartmentSectionStyle2";
import FeaturesSectionStyle2 from "../Section/FeaturesSection/FeaturesSectionStyle2";
import TeamSection from "../Section/TeamSection";
import GallerySection from "../Section/GallerySection";
import AwardSectionStyle2 from "../Section/AwardSection/AwardSectionStyle2";
import { pageTitle } from "../../helpers/PageTitle";


const departmentData = [
  {
    title: "Comprehensive Fertility Diagnostics",
    subTitle:
      "Routine Blood Test, Complete Hormonal Profile (FSH, LH, PRL, T3/T4/TSH, AMH, Estradiol, Testosterone), HSG, Transvaginal & Abdominal Ultrasound, Semen Analysis, Diagnostic Laparoscopy & Hysteroscopy",
    // "Routine Blood Test. Complete Hormonal Profile FSH, LH, PRL, T3T4T5H, Amn, Estradiol, Testostorane etc. Hysterosalpingography (HSG). Transvaginal & Abdominal Ultrasound. Semen Analysis, Diagnostic Laparoscopy, & Hysteroscopy",
    iconUrl: "/images/icons/calendar_white.svg",
    href: "#",
    // /departments/department-details
  },
  {
    title: "Fertility-Enhancing Endoscopic Surgeries",
    subTitle:
      "Advanced Laparoscopic & Hysteroscopic Surgeries for Fibroids, Septum, Endometriosis, etc.",
    iconUrl: "/images/icons/calendar_white.svg",
    href: "#",
    // href: "/departments/department-details",
  },
  {
    title: "Counselling",
    subTitle:
      "Extensive counseling of couples to individualize their treatment and assist in decision-making.",
    iconUrl: "/images/icons/calendar_white.svg",
    href: "#",
    // href: "/departments/department-details",
  },
  {
    title: "High-Risk Pregnancy Management",
    subTitle: "We provide comprehensive care to high-risk pregnant patients.",
    iconUrl: "/images/icons/calendar_white.svg",
    href: "#",
    // href: "/departments/department-details",
  },
  // {
  //   title: "Mental health services",
  //   subTitle:
  //     "Counseling, therapy, and other services to help patients manage mental health conditions",
  //   iconUrl: "/images/icons/calendar_white.svg",
  //   href: "#",
  //   // href: "/departments/department-details",
  // },
];
const featureListData = [
  {
    title: "Dr. Archana Shrivastav",
    subTitle: `Welcome to Jabalpur Fertility Center, situated in Jabalpur, Madhya Pradesh, where under the expert leadership of Dr. Archana Shrivastav, we offer a comprehensive suite of advanced fertility treatments, including IVF, ICSI, IUI, Hysteroscopy, and Laparoscopy, as well as specialized services such as Embryo, Egg, and Sperm Donation, all within our state-of-the-art facility designed to provide an exceptional healthcare experience, demonstrating our unwavering commitment to addressing and resolving a wide range of fertility challenges with the utmost effectiveness and compassionate care, earning the trust and confidence of our valued patients.`,
    iconUrl: "images/icons/professional.svg",
  },
];
const teamData = [
  {
    imgUrl: "images/about/Dr.Archana.png",
    name: "Dr. Archana Shrivastav",
    designation: "MBBS , DGO",
    description:
      "Dr. Archana Shrivastav has 24 years of experience in Obstetrics, Gynecology, and Laparoscopy, specializing in fertility treatment and advanced laparoscopic surgeries.",
    social: [
      {
        icon: "fa6-brands:facebook-f",
        href: "https://m.facebook.com/jabalpurfertilitycentre/",
      },
      {
        icon: "fa6-brands:linkedin-in",
        href: "https://www.linkedin.com/in/jabalpur-fertility-centre-97504a227/",
      },
      { icon: "fa6-brands:twitter", href: "https://x.com/jabalpurivf1" },
    ],
  },
];
const galleryData = [
  { imgUrl: "/images/about/IMG_3267.JPG" },
  { imgUrl: "/images/about/IMG_3206.JPG" },
  { imgUrl: "/images/about/IMG_3181.JPG" },
  { imgUrl: "/images/about/IMG_3156.JPG" },
  { imgUrl: "/images/about/IMG_3116.JPG" },
  { imgUrl: "/images/about/IMG_3115.JPG" },
  { imgUrl: "/images/about/IMG_3045.JPG" },
  { imgUrl: "/images/about/IMG_2986.JPG" },
  { imgUrl: "/images/about/IMG_2975.JPG" },
  { imgUrl: "/images/about/IMG_3156.JPG" },
  { imgUrl: "/images/about/IMG_3116.JPG" },
];
const awardData = [
  {
    iconUrl: "/images/icons/award.svg",
    title: "Recipient of the ISAR Rising Star Award in February 2024.",
  },
  {
    iconUrl: "/images/icons/award.svg",
    title:
      "Member of the European Society of Human Reproduction and Embryology (ESHRE).",
  },
  {
    iconUrl: "/images/icons/award.svg",
    title:
      "Past Secretary of the Indian Medical Association, Jabalpur Chapter.",
  },
  {
    iconUrl: "/images/icons/award.svg",
    title:
      "Past Vice President of the Jabalpur Obstetrics and Gynaecological Society.",
  },
];

export default function About() {
  pageTitle("About");
  return (
    <>
    <Helmet>
      <title>About Us | Dr. Archana Shrivastav's Jabalpur Fertility Center</title>
      <meta
          name="description"
          content="Welcome to Jabalpur Fertility Center, offering advanced fertility treatments like IVF and compassionate care under Dr. Archana Shrivastav's expert leadership."
        />
      <link rel="canonical" href="https://jabalpurivfcentre.com/about" />
    </Helmet>
      <BannerSectionStyle3
        bgUrl="/images/about/AboutBanner_bg.png"
        // imgUrl="/images/about/banner_img.png"
        title="Welcome to <br />Jabalpur Fertility Center"
        subTitle="Unlock Your IVF Journey: Experience Wellness, Hope, and Success with Us!"
      />

      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="About Jabalpur Fertility Center"
          videoUrl="/videos/ivf.mp4"
          // imgUrl="/images/icons/JAbalpurFertilityCentre.png"
          data={featureListData}
        />
      </Section>

      <Section
      // topMd={200} topLg={150} topXl={110}
      >
        <DepartmentSectionStyle2
          sectionTitle="Provides Our Best Services"
          sectionTitleUp="SERVICES"
          data={departmentData}
        />
      </Section>

      <Section topMd={190} topLg={145} topXl={105}>
        <TeamSection
          sectionTitle="Expert Doctor"
          sectionTitleUp="MEET OUR"
          data={teamData}
        />
      </Section>

      <Section
        topMd={190}
        topLg={135}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AwardSectionStyle2
          sectionTitle="Winning Awards and <br />Recognition"
          sectionTitleUp="AWARDS"
          sectionSubTitle="We have been recognized for our commitment to <br />excellence in healthcare."
          data={awardData}
        />
      </Section>

      <Section
        topMd={170}
        topLg={10}
        topXl={80}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <GallerySection
          sectionTitle="Our Facilities and <br />Latest Activities"
          sectionTitleUp="HAVE A LOOK AT"
          data={galleryData}
        />
      </Section>
    </>
  );
}
